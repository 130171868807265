import DocumentLayout from "../layout/DocumentLayout";
import { DocChapter, DocParagraph } from "../common/DocPage";
import { MetaTags } from "../layout/BaseLayout";
import MetaDecorator from "../layout/MetaDecorator";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const OfferPage = () => {
  const title = "Оферта - " + process.env.REACT_APP_WEBSITE_NAME;
  const meta: MetaTags = {
    description: "Оферта",
    keywords: "Оферта,offer",
  };
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <>
      <MetaDecorator title={title} url="/policy" />
      <DocumentLayout
        meta={meta}
        title={title}
        documentTitle={"ПУБЛИЧНАЯ ОФЕРТА НА АРЕНДУ ТРАНСПОРТНОГО СРЕДСТВА"}
      >
        <DocChapter>
          Настоящая публичная оферта (далее — «Оферта») является предложением
          ООО «Восход» (далее — «Арендодатель»), в лице Генерального директора
          Свирко Кирилла Андреевича, действующего на основании Устава, заключить
          договор аренды транспортного средства на нижеследующих условиях с
          любым лицом, которое примет данное предложение (далее — «Арендатор»).
        </DocChapter>
        <DocChapter header={"1. Общие условия"}>
          <DocParagraph prefix={"1.1."}>
            {" "}
            Акцепт настоящей Оферты (принятие условий) осуществляется путем
            подписания Акта приема-передачи автомобиля в бумажном или
            электронном виде.
          </DocParagraph>
          <DocParagraph prefix={"1.2."}>
            {" "}
            С момента акцепта Оферты, Арендатор и Арендодатель вступают в
            договорные отношения аренды транспортного средства на условиях,
            изложенных в настоящей Оферте.
          </DocParagraph>
          <DocParagraph prefix={"1.3."}>
            {" "}
            Арендатор обязуется соблюдать все условия Оферты, в том числе
            правила эксплуатации транспортного средства, указанные в приложении
            к Оферте. .
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"2. Предмет договора"}>
          <DocParagraph prefix={"2.1."}>
            {" "}
            Арендодатель предоставляет Арендатору автомобиль во временное
            владение и пользование на условиях аренды, без оказания услуг по
            управлению транспортным средством.
          </DocParagraph>
          <DocParagraph prefix={"2.2."}>
            {" "}
            Аренда осуществляется на основании Акта приема-передачи, который
            оформляется в момент передачи автомобиля и включает описание
            состояния автомобиля.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"3. Права и обязанности Арендатора"}>
          <DocParagraph prefix={"3.1."}>
            {" "}
            Арендатор обязуется использовать автомобиль только в соответствии с
            его целевым назначением и не передавать управление третьим лицам без
            согласия Арендодателя.
          </DocParagraph>
          <DocParagraph prefix={"3.2."}>
            {" "}
            Арендатор несет ответственность за все расходы, связанные с
            эксплуатацией автомобиля, включая топливо, мойку, парковку, оплату
            штрафов и иных сборов.
          </DocParagraph>
          <DocParagraph prefix={"3.3."}>
            {" "}
            Арендатор обязуется самостоятельно страховать свою ответственность
            перед третьими лицами по обязательному страхованию гражданской
            ответственности владельцев транспортных средств (ОСАГО) в
            соответствии с законодательством Российской Федерации. Арендатор
            обязуется предоставить арендодателю копию полиса ОСАГО и
            подтверждение его действительности.
          </DocParagraph>
          <DocParagraph prefix={"3.4."}>
            {" "}
            Арендатор обязуется возвращать автомобиль в оговоренные сроки в
            состоянии, не худшем, чем указано в Акте приема-передачи, с тем же
            количеством топлива и в чистом виде.
          </DocParagraph>
          <DocParagraph prefix={"3.5."}>
            {" "}
            О сдаче автомобиля Арендатор обязуется предупредить Арендодателя за
            7 суток до предполагаемой даты возврата. В случае досрочного
            возврата автомобиля без предварительного уведомления Арендодателя за
            7 суток, арендная плата пересчитывается по тарифу, соответствующему
            фактическому количеству дней аренды, с удержанием арендной платы за
            два дополнительных дня.
          </DocParagraph>
          <DocParagraph prefix={"3.6."}>
            {" "}
            Списание арендной платы производится ежедневно в 9:00 по Московскому
            времени. В случае если на 09:00 долг по аренде составляет более -500
            руб, автомобиль блокируется автоматически в 12:00 по Московскому
            времени, и начисляется штраф в размере 500 руб за каждый день
            просрочки. Условия блокировки автомобиля должны быть строго
            обоснованы и соответствовать нарушениям со стороны арендатора.
          </DocParagraph>
          <DocParagraph prefix={"3.7."}>
            {" "}
            Арендатор обязуется использовать автомобиль исключительно на
            территории Санкт-Петербурга и Ленинградской области. В случае выезда
            за пределы этих территорий без согласования с Арендодателем,
            автомобиль может быть заблокирован, и автомобиль будет передан на
            перехват ГБР.
          </DocParagraph>
          <DocParagraph prefix={"3.8."}>
            {" "}
            Курение в салоне автомобиля (в том числе клиентами) запрещено. В
            случае выявления факта курения — штраф 5000 руб за первое нарушение
            и 10 000 руб за каждое последующее.
          </DocParagraph>
          <DocParagraph prefix={"3.9."}>
            {" "}
            Арендатор обязан сдавать автомобиль после мойки и уборки салона. В
            случае возврата автомобиля без соблюдения этих условий, может быть
            наложен штраф в размере 10 000 руб.
          </DocParagraph>
          <DocParagraph prefix={"3.10."}>
            {" "}
            В случае неявки на ТО по записи — штраф в размере 1000 руб.
          </DocParagraph>
          <DocParagraph prefix={"3.11."}>
            {" "}
            Автомобиль предоставляется для планового осмотра в парк в
            обязательном порядке раз в 10 дней либо по запросу сотрудников
            парка. Машина для осмотра предоставляется в чистом виде.
          </DocParagraph>
          <DocParagraph prefix={"3.12."}>
            {" "}
            Время, затраченное на шиномонтаж, плановый технический осмотр, вызов
            в офис, получение документов в ГИБДД и другие действия, не
            компенсируется. Арендатор имеет право на смену автомобиля на другой
            в случае, если указанные действия требуют значительного времени и
            влияют на его использование автомобиля.
          </DocParagraph>
          <DocParagraph prefix={"3.13."}>
            {" "}
            В случае превышения скорости на 20 км/ч или более трижды, на
            Арендатора накладывается штраф в размере 300 руб за каждый
            зафиксированный факт превышения. Фиксация фактов превышения
            скоростного режима осуществляется с помощью телематического
            оборудования, установленного на автомобиль.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"4. Права и обязанности Арендодателя"}>
          <DocParagraph prefix={"4.1."}>
            {" "}
            Арендодатель обязуется предоставить технически исправный автомобиль
            в оговоренное время и на условиях, указанных в настоящей Оферте.
          </DocParagraph>
          <DocParagraph prefix={"4.2."}>
            {" "}
            Арендодатель не несет ответственности за ущерб, причиненный
            Арендатору в результате неисправностей автомобиля, если они
            произошли не по вине Арендодателя.
          </DocParagraph>
          <DocParagraph prefix={"4.3."}>
            {" "}
            Стоимость выезда сотрудника Арендодателя для решения технических
            проблем автомобиля, появившихся по вине Арендатора, либо забор
            машины со штрафной стоянки составляет 5000 руб.
          </DocParagraph>
          <DocParagraph prefix={"4.4."}>
            {" "}
            За каждый зафиксированный официальными органами факт нарушения
            правил дорожного движения (ПДД) арендатором, арендодатель имеет
            право наложить собственный штраф в размере десятикратной суммы
            официального штрафа. В случае неуплаты данного штрафа арендодателем,
            сумма может быть взыскана через суд либо судебных приставов. Штрафы
            должны быть пропорциональны и соответствовать тяжести нарушения,
            чтобы избежать их оспаривания в суде.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"5. Порядок оплаты"}>
          <DocParagraph prefix={"5.1."}>
            {" "}
            Арендатор обязуется внести предоплату за аренду автомобиля в полном
            размере через доступные каналы оплаты до момента передачи
            автомобиля.
          </DocParagraph>
          <DocParagraph prefix={"5.2."}>
            {" "}
            Списание арендной платы и других платежей происходит автоматически в
            соответствии с тарифами, указанными в приложении к Оферте.
          </DocParagraph>
          <DocParagraph prefix={"5.3."}>
            {" "}
            В случае недостаточности средств на счету Арендатора, Арендодатель
            имеет право заблокировать автомобиль и начислить штраф. Блокировка
            автомобиля должна быть пропорциональной нарушениям, чтобы избежать
            возможных судебных споров.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"6. Ответственность сторон"}>
          <DocParagraph prefix={"6.1."}>
            {" "}
            В соответствии с пунктом 1 статьи 1079 Гражданского кодекса
            Российской Федерации, ответственность за вред, причиненный третьим
            лицам в результате эксплуатации арендованного автомобиля (источника
            повышенной опасности), несет арендатор, если не докажет, что вред
            возник вследствие непреодолимой силы или умысла потерпевшего.
          </DocParagraph>
          <DocParagraph prefix={"6.2."}>
            {" "}
            Арендатор обязуется полностью возместить арендодателю любые убытки,
            судебные издержки, штрафы и компенсации, которые могут быть
            предъявлены арендодателю третьими лицами или государственными
            органами в связи с ущербом, нанесенным арендованным автомобилем в
            период его эксплуатации арендатором.
          </DocParagraph>
          <DocParagraph prefix={"6.3."}>
            {" "}
            Арендодатель освобождается от любой ответственности перед третьими
            лицами за ущерб, причиненный автомобилем во время его эксплуатации
            арендатором. Арендатор принимает на себя все обязательства по
            урегулированию претензий и исков, предъявленных третьими лицами в
            связи с использованием арендованного автомобиля.
          </DocParagraph>
          <DocParagraph prefix={"6.4."}>
            {" "}
            В случае, если к арендодателю предъявлен судебный иск или иная
            претензия в связи с действиями арендатора или эксплуатацией
            арендованного автомобиля, арендатор обязуется:
            <br /> - Принять на себя защиту арендодателя в таком споре. <br /> -
            Возместить арендодателю все понесенные расходы, включая, но не
            ограничиваясь, расходы на оплату услуг адвокатов и юристов.
          </DocParagraph>
          <DocParagraph prefix={"6.5."}>
            {" "}
            Арендатор несет полную ответственность за нарушение условий
            настоящей Оферты, и в случае возникновения претензий со стороны
            третьих лиц или государственных органов, связанных с эксплуатацией
            автомобиля, обязуется возместить арендодателю все связанные с этим
            убытки и расходы.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"7. Форс-мажор"}>
          <DocParagraph prefix={"7.1."}>
            {" "}
            Стороны освобождаются от ответственности за неисполнение или
            ненадлежащее исполнение обязательств по настоящей Оферте в случае
            наступления обстоятельств непреодолимой силы (форс-мажор). Под
            форс-мажорными обстоятельствами понимаются события, которые не могли
            быть предвидены и предотвращены разумными мерами, такие как
            природные катастрофы, акты военных действий, террористические акты,
            введение государственных ограничений и запретов.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"8. Заключительные положения"}>
          <DocParagraph prefix={"8.1."}>
            {" "}
            Настоящая Оферта вступает в силу с момента ее акцепта Арендатором и
            действует до полного исполнения Сторонами своих обязательств.
          </DocParagraph>
          <DocParagraph prefix={"8.2."}>
            {" "}
            Все изменения и дополнения к Оферте публикуются на сайте
            Арендодателя и вступают в силу с момента их публикации.
          </DocParagraph>
        </DocChapter>
        <DocChapter header={"9. Контактная информация Арендодателя"}>
          <DocParagraph>ООО «Восход»</DocParagraph>
          <DocParagraph>ОГРН/ИНН: 1127847512430/7811532390 </DocParagraph>
          <DocParagraph>
            Адрес: 197374, город Санкт-Петербург, Торфяная дор., д. 7, литера Ф,
            часть помещения 1-н, кабинет 3, офис 104
          </DocParagraph>
          <DocParagraph>Банковские реквизиты:</DocParagraph>
          <DocParagraph>Расчетный счет № 40702810010000084656</DocParagraph>
          <DocParagraph>Банк: АО "ТИНЬКОФФ БАНК"</DocParagraph>
          <DocParagraph>
            Корреспондентский счет № 30101810145250000974
          </DocParagraph>
          <DocParagraph>БИК: 044525974</DocParagraph>
          <DocParagraph>Телефон: 8 (812) 317-68-15</DocParagraph>
          <DocParagraph>Генеральный директор Свирко К.А.</DocParagraph>
        </DocChapter>
      </DocumentLayout>
    </>
  );
};

export default OfferPage;
