import React, { FC, ReactNode, useState, useEffect } from "react";
import { PaginationArrow, PaginationItem } from "../Paginator";
import { PaginatedResponse } from "../../../Api";
import "./RentPaginator.scss";
import { isMobile } from "react-device-detect";

const RentPaginator: FC<{
  data: PaginatedResponse;
  activePage: number;
}> = ({ data, activePage }) => {
  const itemsPerPage = isMobile ? 3 : 5;
  const [items, setItems] = useState<Array<ReactNode>>([]);
  const [pagesGroups, setPagesGroups] = useState<{ [key: number]: number[] }>(
    {}
  );
  const [activePagesGroups, setActivePagesGroups] = useState<number>(1);

  const createPageGroups = (totalPages: number, itemsPerGroup: number) => {
    const pageGroups: Record<number, number[]> = {};
    let groupIndex = 1;

    for (let i = 0; i < totalPages; i += itemsPerGroup) {
      pageGroups[groupIndex] = Array.from(
        { length: Math.min(itemsPerGroup, totalPages - i) },
        (_, index) => i + index + 1
      );
      groupIndex++;
    }
    return pageGroups;
  };

  useEffect(() => {
    const groups = createPageGroups(data.pages, itemsPerPage);
    setPagesGroups(groups);

    // Определяем активную группу на основе activePage
    const initialGroup = Math.ceil(activePage / itemsPerPage);
    setActivePagesGroups(initialGroup);
  }, [data.pages, activePage]);

  useEffect(() => {
    let pages: ReactNode[] = [];
    for (let number = 1; number <= data.pages; number++) {
      pages.push(
        <PaginationItem
          link={`/rent/page/${number}`}
          key={number}
          active={number === activePage}
        >
          {number}
        </PaginationItem>
      );
    }
    setItems(pages);
  }, [data.pages, activePage]);

  const handlePrevClick = () => {
    if (activePagesGroups > 1) {
      setActivePagesGroups((prevGroup) => prevGroup - 1);
    }
  };

  const handleNextClick = () => {
    if (activePagesGroups < Object.keys(pagesGroups).length) {
      setActivePagesGroups((prevGroup) => prevGroup + 1);
    }
  };

  const handleLastClick = () => {
    setActivePagesGroups(Object.keys(pagesGroups).length);
  };

  if (data.pages <= 1) {
    return null;
  }

  return (
    <div className="paginator">
      <PaginationArrow disabled={activePagesGroups === 1} link={`/rent/page/1`}>
        <PaginationItem
          link={`/rent/page/${1}`}
          key={1}
          active={1 === activePage}
        >
          {1}
        </PaginationItem>
      </PaginationArrow>

      {activePagesGroups > 1 && (
        <div className="rent-paginator-arrow" onClick={handlePrevClick}>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6641 15.5L6.66406 10.5L11.6641 5.5"
              stroke="#222222"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      {pagesGroups[activePagesGroups]?.map((page) => items[page - 1])}

      {activePagesGroups < Object.keys(pagesGroups).length && (
        <div className="rent-paginator-arrow" onClick={handleNextClick}>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.4974 17.1667L14.1641 11.3333L7.4974 5.5"
              stroke="#222222"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      {activePagesGroups !== Object.keys(pagesGroups).length && (
        <div className="rent-paginator-arrow" onClick={handleLastClick}>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0052 17.1667L16.6719 11.3333L10.0052 5.5"
              stroke="#222222"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33333 17.1667L10 11.3333L3.33333 5.5"
              stroke="#222222"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      <PaginationArrow
        disabled={activePagesGroups === Object.keys(pagesGroups).length}
        link={`/rent/page/${data.pages}`}
      >
        <PaginationItem
          link={`/rent/page/${data.pages}`}
          key={data.pages}
          active={data.pages === activePage}
        >
          {data.pages}
        </PaginationItem>
      </PaginationArrow>
    </div>
  );
};

export default RentPaginator;
