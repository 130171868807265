import React from "react";
import { Container } from "react-bootstrap";
import BaseLayout, { BaseLayoutProps } from "./BaseLayout";
import { useLocation, useNavigate } from "react-router-dom";
import DocPage from "../common/DocPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

type DocumentLayoutProps = {
  documentTitle: string;
  children: any;
};
const DocumentLayout: React.FunctionComponent<
  BaseLayoutProps & DocumentLayoutProps
> = (props: BaseLayoutProps & DocumentLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const back = () => {
    if (location.key !== "default") navigate(-1);
    else navigate("/");
  };
  return (
    <BaseLayout {...props} headerType={"logo"} noFooter noTopPadding noSend>
      <Container fluid={"lg"} style={{ maxWidth: "880px" }}>
        {isMobile && <div style={{height:'45px', width: '10px'}}></div>}
        <DocPage header={props.documentTitle}>{props.children}</DocPage>
      </Container>
    </BaseLayout>
  );
};

export default DocumentLayout;
