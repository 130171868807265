import "./ShareButton.scss";
import ShareIcon from "./../../../images/index/shareIcon.svg";
import ShareRedIcon from "./../../../images/index/shareRedIcon.svg";
import { useEffect, useRef, useState } from "react";
import ModalFormTemplate, { ModalTemplateInput } from "../ModalFormTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { CarDataType } from "../../../types/RentTypes";
import { Link, useLocation } from "react-router-dom";
import { FooterLink } from "../../layout/Footer";
import vk from "./../../../images/common/footer/social-icons-dark/vk.svg";
import wh from "./../../../images/common/footer/social-icons-dark/whatsapp.svg";
import telegram from "./../../../images/common/footer/social-icons-dark/telegram.svg";
import mail from "./../../../images/common/footer/social-icons-dark/mail.svg";
import vkc from "./../../../images/common/footer/social-icons-colored/vk.png";
import whc from "./../../../images/common/footer/social-icons-colored/whatsapp.png";
import telegramc from "./../../../images/common/footer/social-icons-colored/telegram.png";
import mailc from "./../../../images/common/footer/social-icons-colored/mail.png";
import { BrowserView } from "react-device-detect";

export const ShareButton = ({
  type,
  id,
  active,
  car,
}: {
  type: "mobile" | "desctop";
  id?: string;
  active: boolean;
  car: CarDataType;
}) => {
  const location = useLocation();
  const [isShareModalOpen, setIsShareOpen] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [recepientMailStep, setRecepientMailStep] = useState<boolean>(false);
  const [recepientMailValue, setRecepientMailValue] = useState<string>("");

  const handleOpen = () => {
    setIsShareOpen(true);
  };
  const handleClose = () => {
    recepientMailStep ? setRecepientMailStep(false) : setIsShareOpen(false);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  const mobileSharing = async () => {
    if (typeof navigator !== "undefined") {
      try {
        await navigator.share({
          title: `${car.brand} ${car.model}`,
          url: process.env.REACT_APP_API_HOST + location.pathname,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("Navigator object is not available.");
    }
  };

  const shareByEmail = () => {
    const subject = encodeURIComponent(car.brand + " " + car.model);
    const body = encodeURIComponent(
      process.env.REACT_APP_API_HOST + location.pathname
    );
    const email = recepientMailValue;

    // Создаем ссылку для отправки email
    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

    return mailtoLink;
  };

  useEffect(() => {
    copySuccess === true &&
      setTimeout(() => {
        setCopySuccess(false);
      }, 6000);
  }, [copySuccess]);

  const [currentShareIcon, setCurrentShareIcon] = useState<string>(ShareIcon);

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  switch (type) {
    case "desctop":
      return (
        <div className="share-button_container_label">
          <div
            className="share-label"
            onClick={handleOpen}
            onMouseEnter={() => setCurrentShareIcon(ShareRedIcon)}
            onMouseLeave={() => setCurrentShareIcon(ShareIcon)}
          >
            <img src={currentShareIcon} alt="share-icon" />
            Поделиться
          </div>
          <BrowserView>
            <ModalFormTemplate
              show={isShareModalOpen}
              centered
              size={"sm"}
              image={false}
              onHide={() => setIsShareOpen(false)}
              className="share_modal"
            >
              <div className="share-modal_content">
                <div className={"mb-px-35"}>
                  <button
                    className={
                      "default-link font-size-16 font-weight-semibold text-hover-default"
                    }
                    onClick={() => handleClose()}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                    &nbsp;&nbsp;ВЕРНУТЬСЯ
                  </button>
                </div>
                <div>
                  <div
                    className={
                      "call-content-text-header font-size-32 line-height-100 mb-px-40"
                    }
                  >
                    Поделится
                    <br />
                    <p className="font-size-24">
                      {" "}
                      {car.brand} {car.model}{" "}
                      <span style={{ color: "#BABCBF" }}>{car.regnum}</span>
                    </p>
                  </div>
                </div>
                {!recepientMailStep ? (
                  <>
                    <div className={"share-links mb-px-40"}>
                      <div
                        onMouseEnter={() => setHoveredIndex(1)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          opacity:
                            hoveredIndex === 1
                              ? "1"
                              : hoveredIndex !== null
                              ? "0.4"
                              : "1",
                        }}
                      >
                        <FooterLink
                          img={vk}
                          hover={vkc}
                          className="share-link"
                          link={`https://vk.com/share.php?url=${
                            process.env.REACT_APP_API_HOST + location.pathname
                          }&title=${car.brand}%20${
                            car.model
                          }&utm_source=share2`}
                        />
                      </div>
                      <div
                        onMouseEnter={() => setHoveredIndex(2)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          opacity:
                            hoveredIndex === 2
                              ? "1"
                              : hoveredIndex !== null
                              ? "0.4"
                              : "1",
                        }}
                      >
                        <FooterLink
                          img={wh}
                          className="share-link"
                          hover={whc}
                          link={`whatsapp://send?text=${
                            process.env.REACT_APP_API_HOST + location.pathname
                          }`}
                        />
                      </div>
                      <div
                        onMouseEnter={() => setHoveredIndex(3)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          opacity:
                            hoveredIndex === 3
                              ? "1"
                              : hoveredIndex !== null
                              ? "0.4"
                              : "1",
                        }}
                      >
                        <FooterLink
                          img={telegram}
                          className="share-link"
                          hover={telegramc}
                          link={`https://t.me/share/url?url=${
                            process.env.REACT_APP_API_HOST + location.pathname
                          }&text=${car.brand}%20${car.model}&utm_source=share2`}
                        />
                      </div>
                      <div
                        onClick={() => setRecepientMailStep(true)}
                        onMouseEnter={() => setHoveredIndex(4)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                          opacity:
                            hoveredIndex === 4
                              ? "1"
                              : hoveredIndex !== null
                              ? "0.4"
                              : "1",
                        }}
                      >
                        <FooterLink
                          img={mail}
                          className="share-link"
                          hover={mailc}
                          link={""}
                        />
                      </div>
                    </div>
                    <div className={" mb-px-25"}>
                      <ModalTemplateInput
                        small={false}
                        value={
                          process.env.REACT_APP_API_HOST + location.pathname
                        }
                        disable
                      />
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "20px" }}
                    >
                      <button
                        className={"site-btn small dark"}
                        onClick={() =>
                          copyToClipboard(
                            process.env.REACT_APP_API_HOST + location.pathname
                          )
                        }
                      >
                        Скопировать ссылку
                      </button>
                      {copySuccess && (
                        <div
                          className={
                            "default-link font-size-14 font-weight-600 copy-success d-flex"
                          }
                          style={{ alignItems: "center", gap: "10px" }}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2588_7157)">
                              <path
                                d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                                stroke="#BABCBF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />
                              <path
                                d="M6.25781 9.18126L8.14096 10.9599L11.7361 7.04688"
                                stroke="#BABCBF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2588_7157">
                                <rect width="18" height="18" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          СКОПИРОВАНА
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className={" mb-px-20"}>
                      <ModalTemplateInput
                        small={false}
                        placeholder="E-mail"
                        onChange={(e) => setRecepientMailValue(e.target.value)}
                      />
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <Link
                        className={"site-btn small dark"}
                        style={{ maxWidth: "139px" }}
                        to={shareByEmail()}
                        target="_blank"
                      >
                        Отправить
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </ModalFormTemplate>
          </BrowserView>
        </div>
      );
    case "mobile":
      return (
        <div
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            mobileSharing();
          }}
          className="share-button_container_mobile"
        >
          <div className="share-label">
            <img src={ShareIcon} alt="share-icon" />
            <p> Поделится</p>
          </div>
        </div>
      );
    default:
      return (
        <div
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            handleOpen();
          }}
          className="share-button_container"
        >
          <img src={ShareIcon} alt="share" className="share-button_icon" />
        </div>
      );
  }
};
