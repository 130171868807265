import { Link } from "react-router-dom";
import "./QuickRentMobile.scss";

export const QuickRentMobile = () => {
  return (
    <div className="quick-rent">
      <div className="quick-rent_line"></div>
      <div className="quick-rent_content">
        <p className="quick-rent_content-title">
          БЫСТРАЯ АРЕНДА АВТОМОБИЛЕЙ
        </p>
        <p>
          Вы можете забронировать автомобиль на сайте самостоятельно или
          позвонить по телефону:
        </p>
        <Link
          to="tel:+78123176815"
          className="font-size-12"
          style={{ color: "#BF3535", textDecoration: "none" }}
        >
          +7 (812) 317-68-15
        </Link>
      </div>
    </div>
  );
};
