import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import IndexPage, { indexLoader } from "../components/pages/IndexPage";
import CatalogPage from "../components/pages/CatalogPage";
import CarPage, { carDataLoader } from "../components/pages/CarPage";
import RentPage from "../components/pages/RentPage";
import ProgramsPage, {
  faqProgramsLoader,
} from "../components/pages/ProgramsPage";
import FaqPage, { faqLoader } from "../components/pages/FaqPage";
import ContactsPage from "../components/pages/ContactsPage";
import PolicyPage from "../components/pages/PolicyPage";
import UserAgreementPage from "../components/pages/UserAgreementPage";
import PersonalAccountPage from "../components/pages/PersonalAccount/PersonalAccountPage";
import OfferPage from "../components/pages/OfferPage";
import NotExistsPage from "../components/pages/NotExistsPage";
import App from "../App";
import Payment from "../components/pages/Payment";
import PersonalAccountRentHistoryPage from "../components/pages/PersonalAccountRentHistory/PersonalAccountRentHistoryPage";
import PersonalAccountLeasingPage from "../components/pages/PersonalAccountLeasing/PersonalAccountLeasingPage";
import TransactionsPage from "../components/pages/Transactions/TransactionsPage";
import FinesPage from "../components/pages/Fines/FinesPage";
import { PaymentsPage } from "../components/pages/Payments/PaymentsPage";
import SubscriptionsPage from "../components/pages/Subscriptions/SubscriptionsPage";
import RentItemPage from "../components/pages/Rent/RentItemPage/RentItemPage";
import PersonalAccountRaiting from "../components/pages/PersonalAccountRaiting/PersonalAccountRating";
import { ProtectedRoutes } from "../utils/protectedRotes";
import StoPage from "../components/pages/Sto/StoPage";
import BuyOutPage from "../components/pages/BuyutPage";
import { ConditionsPage } from "../components/pages/ConditionsPage";
import { JobPage } from "../components/pages/Job/JobPage";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<App />}>
      <Route index element={<IndexPage />} loader={indexLoader} />
      {/* <Route path={"catalog"} element={<CatalogPage />} />
      <Route
        path={"catalog/:carID"}
        element={<CarPage />}
        loader={carDataLoader}
      /> */}
      <Route path="rent/page/:id">
        <Route index element={<RentPage />} />
        <Route path={"car/:carID"} element={<RentItemPage />} />
      </Route>
      <Route path="payment/confirm/car/:id/pid/:pid" element={<Payment />} />
      <Route
        path={"programs"}
        element={<ProgramsPage />}
        loader={faqProgramsLoader}
      />
      <Route
        path={"buyout"}
        element={<BuyOutPage />}
        loader={faqProgramsLoader}
      />
      <Route path={"faq"} element={<FaqPage />} loader={faqLoader} />
      <Route path={"contacts"} element={<ContactsPage />} />
      <Route path={"policy"} element={<PolicyPage />} />
      <Route path={"userAgreement"} element={<UserAgreementPage />} />
      <Route path="personal-account" element={<PersonalAccountPage />} />
      <Route path={"conditions"} element={<ConditionsPage />} />
      <Route path="service" element={<StoPage />} />
      <Route path={"service/job"} element={<JobPage />} />
      <Route
        path="personal-account/rent-history"
        element={
          <ProtectedRoutes>
            <PersonalAccountRentHistoryPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="personal-account/balance"
        element={<PersonalAccountPage />}
      />
      <Route
        path="personal-account/leasing"
        element={
          <ProtectedRoutes>
            <PersonalAccountLeasingPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="personal-account/payment"
        element={
          <ProtectedRoutes>
            <PaymentsPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="personal-account/transactions"
        element={
          <ProtectedRoutes>
            <TransactionsPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="personal-account/raiting"
        element={
          <ProtectedRoutes>
            <PersonalAccountRaiting />
          </ProtectedRoutes>
        }
      />
      <Route
        path="personal-account/fines"
        element={
          <ProtectedRoutes>
            <FinesPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path="personal-account/subscriptions"
        element={
          <ProtectedRoutes>
            <SubscriptionsPage />
          </ProtectedRoutes>
        }
      />
      <Route path={"offer"} element={<OfferPage />} />
      <Route path={"*"} element={<NotExistsPage />} />
    </Route>
  )
);
