import { useState } from "react";
import BaseLayout, { MetaTags } from "../../layout/BaseLayout";
import MetaDecorator from "../../layout/MetaDecorator";
import { MainSection } from "./MainSection/MainSection";

export const JobPage = () => {
    const title = 'Вакансии';
    const meta: MetaTags = {
      description: process.env.REACT_APP_WEBSITE_NAME,
      keywords: "leasing,rent,аренда,авто,автомобиль,лизинг,бронирование",
    };
    const [whiteMenu, setWhiteMenu] = useState(false);
    return (
      <>
        <MetaDecorator title={title ?? "Компания Восход"} />
        <BaseLayout
          whiteMenu={true}
          title={title}
          meta={meta}
          headerType={"white"}
          headerSelectedLink={"/service"}
          noTopPadding={true}
        >
            <MainSection />
        </BaseLayout>
      </>
    );
}