import { Container, Row } from "react-bootstrap";
import * as S from "./Mainection.styled";
import CustomBackButton from "../../../common/CustomBackButton/CustomBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useEffect, useState } from "react";
import Calendar from "./../../../../images/common/calendar-red.svg";
import Shield from "./../../../../images/common/shield-red.svg";
import Point from "./../../../../images/common/square-red.svg";
import JobRequestForm from "../JobForm";

export const MainSection = () => {
  const mokJobs = [
    {
      name: "Автомаляр",
      id: 1,
      price: "от 80 000 ₽",
      description: {
        work_experience: "1 - 3 года",
        duties: [
          "Выполнение восстановительных работ по лакокрасочным покрытиям и антикоррозионной защите элементов кузова.",
          "Подготовка поверхности авто под покраску.",
          "Покраска автомобиля, полировка.",
        ],
        requirements: [
          "Опыт работы на аналогичной позиции от 1 года.",
          "Знание технологии окраски автомобиля.",
          "Качество выполняемых работ",
          "Качество и быстрота выполняемых работ.",
        ],
        conditions: [
          "Дружный коллектив",
          "Работа в стабильной компании",
          "График работы оговаривается на собеседовании",
          "Профессиональный инструмент и оборудование",
        ],
      },
    },
    {
      name: "Автомеханик-автослесарь",
      id: 2,
      price: "от 100 000 ₽",
      description: {
        work_experience: "1 - 3 года",
        duties: [
          "Диагностика, обслуживание и ремонт автомобилей.",
          "Поддержание порядка на рабочем месте.",
          "Соблюдения правил техники безопасности.",
        ],
        requirements: [
          "Профильное образование приветствуется.",
          "Опыт работы не менее года в должности (автомеханик, автослесарь).",
          "Знание устройства автомобиля и технологии его ремонта.",
          "Дисциплина, пунктуальность.",
          "Знание шиномонтажа будет Вашим дополнительным ресурсом.",
        ],
        conditions: [
          "Дружный коллектив",
          "Работа в стабильной компании",
          "График работы оговаривается на собеседовании",
          "Профессиональный инструмент и оборудование",
          "Честная зарплата без задержек, высокий процент.",
        ],
      },
    },
    {
      name: "Кузовщик",
      id: 3,
      price: "от 90 000 ₽",
      description: {
        work_experience: "1 - 3 года",
        duties: [
          "Выполнение работ по восстановительному кузовному ремонту легковых автомобилей.",
          "Выполнение разборки сборки автомобиля, вклейка стекол.",
          "Восстановление геометрии кузова аварийных ТС.",
          "Арматурные работы.",
        ],
        requirements: [
          "Выполнение поставленных задач.",
          "Аккуратность.",
          "Ответственность.",
          "Дисциплина, пунктуальность.",
          "Опыт работы кузовщиком от года.",
        ],
        conditions: [
          "Заработная плата сдельная.",
          "Работа в стабильной компании.",
          "Выплаты дважды в месяц.",
        ],
      },
    },
    {
      name: "Автоэлектрик",
      id: 4,
      price: "100 000 ₽ – 150 000 ₽",
      description: {
        work_experience: "1 - 3 года",
        duties: [
          "Поиск и устранение неисправностей в системах автомобилей.",
          "Чтение электросхем, ремонт блоков управления.",
          "Восстановление геометрии кузова аварийных ТС.",
          "Установка дополнительного оборудования: сигнализации, камеры, аудио-видео, парктроники и т.д.",
        ],
        requirements: [
          "Опыт работы диагностом-электриком на автосервисах или у официальных диллеров со сканерами мультибренд обязателен.",
          "Умение работать с диагностическим оборудованием (Autel, Launch).",
          "Ответственность.",
          "Знание устройства автомобилей и технологий ремонта.",
          "Опыт работы кузовщиком от года.",
        ],
        conditions: [
          "График оговаривается индивидуально.",
          "Инструмент и оборудование предоставляется.",
          "Дружный коллектив.",
          "Работа в стабильной компании.",
        ],
      },
    },
    {
      name: "Мастер-приёмщик",
      id: 5,
      price: "70 000 ₽ – 100 000 ₽",
      description: {
        work_experience: "1 - 3 года",
        duties: [
          "Принимать и выдавать автомобили согласно стандартам.",
          "Контролировать рабочие процессы.",
          "Консультировать клиентов, согласовывать сроки и объёмы работ.",
          "Работать с входящими звонками.",
          "Работать в CRM-системе (заказ-наряд, акт выполненных работ).",
          "Поддерживать высокие стандарты клиентского сервиса.",
        ],
        requirements: [
          "Опыт работы на аналогичной должности (мастер-приёмщик) от 6 месяцев техническая грамотность (четкое представление процесса ремонта и обслуживания автомобилей).",
          "Грамотная речь, коммуникабельность, забота о клиенте.",
          "Опыт работы с CRM или/и готовность обучаться.",
          "Стремление узнавать новое и быть лучшим в своём деле.",
        ],
        conditions: [
          "График оговаривается индивидуально.",
          "Дружный коллектив.",
          "Работа в стабильной компании.",
        ],
      },
    },
  ];
  const [activeJob, setActiveJob] = useState<number | null>(
    isMobile ? null : 1
  );
  const activeJobItem = mokJobs.find((item) => item.id === activeJob);

  const [containerHeight, setContainerHeight] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (isMobile) {
      const jobCardElement = document.getElementById('job-card');
      if (jobCardElement) {
        setContainerHeight(jobCardElement.scrollHeight + 20);
      }
    }
  }, [activeJob]);

  return (
    <Container
      className={"h-100"}
      style={{ minHeight: isMobile && activeJob ? containerHeight : "fit-content" }}
    >
      <div className={"d-flex flex-column justify-content-between h-100"}>
        <Row className={"g-3 flex-grow-1"}>
          <S.MainBlock>
            <S.LeftColumn>
              <BrowserView>
                <CustomBackButton path={"/service"}>
                  <FontAwesomeIcon icon={faAngleLeft} size={"sm"} />
                  &nbsp;&nbsp;Вернуться
                </CustomBackButton>
              </BrowserView>
              <S.Header>Вакансии</S.Header>
              <div
                className="cited-mobile_divider"
                style={{ marginLeft: isMobile ? "16px" : "0" }}
              ></div>
              <S.LabelsColumn>
                {mokJobs.map((item) => {
                  return (
                    <S.Label
                      key={item.name}
                      active={activeJob === item.id}
                      onClick={() => setActiveJob(item.id)}
                    >
                      <div>
                        <p>{item.name}</p>
                        {item.price}
                      </div>
                      <FontAwesomeIcon icon={faAngleRight} size={"sm"} />
                    </S.Label>
                  );
                })}
              </S.LabelsColumn>
            </S.LeftColumn>
            <S.RightColumn>
              {activeJobItem && (
                <S.JobLargeCard id={'job-card'}>
                  <MobileView>
                    <S.JobBackButton onClick={() => setActiveJob(null)}>
                      <FontAwesomeIcon icon={faAngleLeft} size={"sm"} />
                      &nbsp;&nbsp;Вакансии
                    </S.JobBackButton>
                  </MobileView>
                  <S.JobHeader>
                    <p>{activeJobItem.name}</p>
                    {activeJobItem.price}
                  </S.JobHeader>
                  <S.JobDescriptionColumn>
                    <S.JobDescriptionSection>
                      <p style={{ fontSize: "16px" }}>
                        Требудемый опыт работы:{" "}
                        {activeJobItem.description.work_experience}
                      </p>
                      <p>
                        {" "}
                        <img
                          src={Calendar}
                          alt="Календарь"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        />
                        Полная занятость
                      </p>
                      <p>
                        <img
                          src={Shield}
                          alt="Календарь"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        />
                        Оформление по ТД РФ
                      </p>
                    </S.JobDescriptionSection>
                    <S.JobDescriptionSection>
                      <p style={{ fontWeight: "600", fontSize: "16px" }}>
                        ОБЯЗАННОСТИ:
                      </p>
                      {activeJobItem.description.duties.map((duty, index) => {
                        return (
                          <p key={index}>
                            <img
                              src={Point}
                              alt="Точка"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            />
                            {duty}
                          </p>
                        );
                      })}
                    </S.JobDescriptionSection>
                    <S.JobDescriptionSection>
                      <p style={{ fontWeight: "600", fontSize: "16px" }}>
                        ТРЕБОВАНИЯ:
                      </p>
                      {activeJobItem.description.requirements.map(
                        (requirement, index) => {
                          return (
                            <p key={index}>
                              <img
                                src={Point}
                                alt="Точка"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              {requirement}
                            </p>
                          );
                        }
                      )}
                    </S.JobDescriptionSection>
                    <S.JobDescriptionSection>
                      <p style={{ fontWeight: "600", fontSize: "16px" }}>
                        УСЛОВИЯ:
                      </p>
                      {activeJobItem.description.conditions.map(
                        (condition, index) => {
                          return (
                            <p key={index}>
                              <img
                                src={Point}
                                alt="Точка"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              {condition}
                            </p>
                          );
                        }
                      )}
                    </S.JobDescriptionSection>
                    <JobRequestForm
                      text={
                        <span className={"order-call_button"}>
                          Откликнуться
                        </span>
                      }
                      small={true}
                      light={false}
                    />
                  </S.JobDescriptionColumn>
                </S.JobLargeCard>
              )}
            </S.RightColumn>
          </S.MainBlock>
        </Row>
      </div>
    </Container>
  );
};
