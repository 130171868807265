import styled from "styled-components";

// media.js
const breakpoints = {
  sm: "480px",
  md: "768px",
};

export const media = {
  sm: `@media (max-width: ${breakpoints.sm})`,
  md: `@media (max-width: ${breakpoints.md})`,
};

export const MainBlock = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  position: relative;
  ${media.sm} {
    margin-top: 94px;
  }
`;

export const LeftColumn = styled.div`
  width: 80%;
  padding-bottom: 100px;
  ${media.sm} {
    width: 100vw;
    min-width: 100vw;
    padding-bottom: 85px;
    transform: translateX(-10px);
  }
`;

export const RightColumn = styled.div`
  width: 120%;
`;

export const Header = styled.div`
  padding: 0 0 0 36px;
  border-left: 6px solid #bf3535;
  font-size: 52px;
  margin: 20px 0 32px 0;
  font-weight: 500;
  text-transform: uppercase;

  ${media.md} {
    font-size: 42px;
    margin: 20px 0 26px 0;
  }

  ${media.sm} {
    font-size: 32px;
    margin: 20px 0 8px 0;
    padding: 0;
    border-left: none;
    margin: 0;
    line-height: 100%;
    padding-bottom: 16px;
    margin-left: 16px;
  }
`;

export const LabelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${media.sm} {
    margin-top: 32px;
  }
`;

export const Label = styled.div<{ active: boolean }>`
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  max-height: 90px;
  box-sizing: border-box;
  padding: 10px 30px 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  ${media.sm} {
    font-size: 12px;
    border-bottom: 1px solid #f2f3f6;
  }
  p {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    ${media.sm} {
      font-size: 20px;
    }
  }
  &:hover {
    background-color: #f2f3f6;
  }
  background-color: ${(props) => (props.active ? "#F2F3F6" : "transparent")};
`;

export const JobLargeCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 40px 0px #00000014;

  background: #ffffff;

  ${media.sm} {
    position: absolute;
    padding: 15px;
    left: 0;
    bottom: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    box-shadow: none;
    padding-bottom: 60px;
  }
`;

export const JobHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f2f3f6;
  font-size: 16px;
  font-weight: 400;

  ${media.sm} {
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    margin-top: 24px;
    padding-bottom: 16px;
    p {
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 100%;
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 32px;
    font-weight: 600;
  }
`;

export const JobDescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 30px;

  ${media.sm} {
    font-size: 12px;
    padding-bottom: 24px;
    button {
      min-width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const JobDescriptionSection = styled.div`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  ${media.sm} {
    font-size: 12px;
  }
  p {
    display: flex;
    align-items: center;
  }
`;

export const JobBackButton = styled.div`
  margin-top: 40px;
  opacity: 0.6;
`;
