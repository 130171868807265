import { Container } from "react-bootstrap";
import "./GuaranteeCard.scss";
import { BrowserView, MobileView } from "react-device-detect";
export const GuaranteeCard = () => {
  return (
    <div className="guarantee_block">
      <Container className={"h-100"}>
        <div className="guarantee_header">
          <p> ГАРАНТИЯ НА РАБОТЫ</p>
          <BrowserView>
            <button
              className="job-button"
              onClick={(e) => {
                e.preventDefault();
                window.location.replace('/service/job');
              }}
            >
              ОТКРЫТЫЕ ВАКАНСИИ
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 17 17"
                fill="none"
              >
                <path
                  d="M7.16602 12.5L11.166 8.5L7.16602 4.5"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </BrowserView>
        </div>
        <div className="tile-row">
          <div className="tile-row_tile">
            <p>ШИРОКИЙ ПРОФИЛЬ</p> Выполняем все виды сервисных работ — от
            замены масла до капитального ремонта двигателя.
          </div>
          <div className="tile-row_tile">
            <p>БОЛЬШОЙ АССОРТИМЕНТ</p> Широкий ассортимент оригинальных
            запчастей, их аналогов и расходного материала.
          </div>
          <div className="tile-row_tile">
            <p>БЫСТРОЕ ОБСЛУЖИВАНИЕ</p> Ремонтируем быстро и качественно в
            оговоренные сроки.
          </div>
          <div className="tile-row_tile">
            <p>РАЗУМНЫЕ ЦЕНЫ</p> Выполняем работы, которые действительно нужны.
            Стоимость услуг согласовывается до выполнения.
          </div>
        </div>
        <div className="red-border-text">
          В распоряжении клиентов комната ожидания с зоной Wi-Fi <br />
          (можно отдохнуть и выпить чашку кофе)
        </div>
        <MobileView>
          <button
            className="job-button_mobile"
            onClick={(e) => {
                window.location.replace('/service/job');
            }}
          >
            ОТКРЫТЫЕ ВАКАНСИИ
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 11.25L10.5 7.5L6.75 3.75"
                stroke="#222222"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </MobileView>
      </Container>
    </div>
  );
};
