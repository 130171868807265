import DocumentMeta from "react-document-meta";
import Cookies from "../common/Cookies";
import {CatalogLayoutProps} from "./RentLayout";
import {useParams} from "react-router-dom";
import CarDetailHeader from "./CarDetailHeader";
import {Container} from "react-bootstrap";
import {SmallFooter} from "./Footer";
import {BaseLayoutProps} from "./BaseLayout";

const RentItemLayout: React.FunctionComponent<BaseLayoutProps> = (
    props: BaseLayoutProps
) => {
    const { id: pageId, carID } = useParams();
    const meta = {
        title: props.title ?? process.env.REACT_APP_WEBSITE_NAME,
        description:
            props.meta?.description ?? process.env.REACT_APP_WEBSITE_DESCRIPTION,
        canonical: window.location.href,
        meta: {
            charset: "utf-8",
            name: {
                keywords:
                    process.env.REACT_APP_WEBSITE_DESCRIPTION +
                    "," +
                    (props.meta?.keywords ?? ""),
            },
        },
    };

    return (
        <DocumentMeta {...meta}>
            <div className={"site"}>
                {!props.noTopPadding && <div className="no-top-padding" />}
                <CarDetailHeader
                    image={props.headerImage}
                    backLink={`/rent/page/${pageId}/car/${carID}`}
                    type={props.headerType ?? "white"}
                    selectedLink={props.headerSelectedLink ?? "/"}
                    backFunc={props.backFunc}
                />
                <main>{props.children}</main>
                <Cookies />
            </div>
        </DocumentMeta>
    );
};

export default RentItemLayout;